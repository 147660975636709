import Game1 from './Game1';
import Game2 from './Game2';
import game1Image from '../assets/game1.png'; // Adjust path as needed
import game2Image from '../assets/game2.png'; // Adjust path as needed

// Games registry
export const GAMES = {
  1: {
    title: "Dino Jump",
    component: Game1,
    description: "Jump over enemies and race to the finish",
    image: game1Image}
  ,
   2: {
     title: "Rainbow Friends Run",
     component: Game2,
     description: "Avoid the Rainbow Friends and get to the safe zone",
     image: game2Image
  }
};

// Helper function to get a game by ID
export const getGameById = (id) => GAMES[id] || null;