import React, { useState, useEffect, useCallback } from 'react';
import { Square, ArrowUp, ArrowDown, ArrowLeft, ArrowRight } from 'lucide-react';

/**
 * @typedef {Object} Position
 * @property {number} x
 * @property {number} y
 */

/**
 * @typedef {Object} Obstacle
 * @property {number} id
 * @property {number} x
 * @property {number} lane
 * @property {'left'|'right'} direction
 * @property {'blue'|'red'|'green'|'orange'|'purple'|'pink'|'yellow'} character
 */

const characterColors = {
  blue: { bg: 'bg-blue-500', text: 'text-black', eyes: 'bg-black', mouth: 'bg-black' },
  red: { bg: 'bg-red-500', text: 'text-white', eyes: 'bg-white', mouth: '' },
  green: { bg: 'bg-green-500', text: 'text-black', eyes: 'bg-black', mouth: 'bg-red-500' },
  orange: { bg: 'bg-orange-500', text: 'text-black', eyes: 'bg-black', mouth: 'bg-yellow-200' },
  purple: { bg: 'bg-purple-500', text: 'text-white', eyes: 'bg-white', mouth: '' },
  pink: { bg: 'bg-pink-500', text: 'text-black', eyes: 'bg-black', mouth: 'bg-gray-800' },
  yellow: { bg: 'bg-yellow-400', text: 'text-black', eyes: 'bg-black', mouth: 'bg-orange-500' }
};

const RainbowFriend = ({ character }) => {
  const colors = characterColors[character];
  
  return (
    <div className={`relative ${colors.bg} w-full h-full rounded-lg flex items-center justify-center`}>
      {/* Eyes */}
      <div className="absolute flex gap-1">
        <div className={`${colors.eyes} w-[20%] h-[20%] rounded-full`}></div>
        <div className={`${colors.eyes} w-[20%] h-[20%] rounded-full`}></div>
      </div>
      {/* Mouth */}
      {colors.mouth && (
        <div className={`absolute bottom-[20%] ${colors.mouth} w-[40%] h-[10%] rounded-full`}></div>
      )}
    </div>
  );
};

function App() {
  const GRID_SIZE = 10;
  const SAFE_ROW = 5; // Middle safe row
  const [playerPos, setPlayerPos] = useState({ x: 5, y: 9 });
  const [obstacles, setObstacles] = useState([]);
  const [gameOver, setGameOver] = useState(false);
  const [score, setScore] = useState(0);

  // Initialize obstacles
  useEffect(() => {
    const characters = ['blue', 'red', 'green', 'orange', 'purple', 'pink', 'yellow'];
    const initialObstacles = [];
    for (let lane = 2; lane < 9; lane++) {
      // Skip the safe row
      if (lane === SAFE_ROW) continue;
      
      for (let i = 0; i < 3; i++) {
        initialObstacles.push({
          id: lane * 10 + i,
          x: Math.floor(Math.random() * GRID_SIZE),
          lane,
          direction: lane % 2 === 0 ? 'left' : 'right',
          character: characters[Math.floor(Math.random() * characters.length)]
        });
      }
    }
    setObstacles(initialObstacles);
  }, []);

  // Move obstacles
  useEffect(() => {
    const intervalId = setInterval(() => {
      setObstacles((currentObstacles) =>
        currentObstacles.map((obstacle) => {
          let newX = obstacle.direction === 'left' 
            ? obstacle.x - 1 
            : obstacle.x + 1;

          if (newX < 0) newX = GRID_SIZE - 1;
          if (newX >= GRID_SIZE) newX = 0;

          return { ...obstacle, x: newX };
        })
      );
    }, 500);

    return () => clearInterval(intervalId);
  }, []);

  // Check collisions
  useEffect(() => {
    const hasCollision = obstacles.some(
      (obstacle) =>
        obstacle.lane === playerPos.y && 
        obstacle.x === playerPos.x
    );

    if (hasCollision) {
      setGameOver(true);
    }

    if (playerPos.y === 0) {
      setScore((prev) => prev + 100);
      setPlayerPos({ x: 5, y: 9 });
    }
  }, [playerPos, obstacles]);

  const movePlayer = useCallback((direction) => {
    if (gameOver) return;

    setPlayerPos((prev) => {
      const newPos = { ...prev };
      switch (direction) {
        case 'up':
          if (prev.y > 0) newPos.y = prev.y - 1;
          break;
        case 'down':
          if (prev.y < GRID_SIZE - 1) newPos.y = prev.y + 1;
          break;
        case 'left':
          if (prev.x > 0) newPos.x = prev.x - 1;
          break;
        case 'right':
          if (prev.x < GRID_SIZE - 1) newPos.x = prev.x + 1;
          break;
      }
      return newPos;
    });
  }, [gameOver]);

  const handleKeyPress = useCallback((e) => {
    // Prevent default browser scrolling behavior
    e.preventDefault();
    
    switch (e.key) {
      case 'ArrowUp':
        movePlayer('up');
        break;
      case 'ArrowDown':
        movePlayer('down');
        break;
      case 'ArrowLeft':
        movePlayer('left');
        break;
      case 'ArrowRight':
        movePlayer('right');
        break;
    }
  }, [movePlayer]);

  useEffect(() => {
    window.addEventListener('keydown', handleKeyPress);
    return () => window.removeEventListener('keydown', handleKeyPress);
  }, [handleKeyPress]);

  const resetGame = () => {
    setPlayerPos({ x: 5, y: 9 });
    setGameOver(false);
    setScore(0);
  };

  return (
    <div className="min-h-screen bg-gray-900 flex flex-col items-center justify-center p-4">
      <div className="w-full max-w-md text-center">
        <div className="mb-4 text-white text-2xl">Score: {score}</div>
        <div className="bg-gray-800 p-2 sm:p-4 rounded-lg">
          {Array.from({ length: GRID_SIZE }).map((_, y) => (
            <div key={y} className="flex">
              {Array.from({ length: GRID_SIZE }).map((_, x) => {
                const isPlayer = playerPos.x === x && playerPos.y === y;
                const obstacle = obstacles.find(
                  (o) => o.x === x && o.lane === y
                );
                const cellColor = y === 0 
                  ? 'bg-green-600' 
                  : y === GRID_SIZE - 1 
                    ? 'bg-blue-600'
                    : y === SAFE_ROW
                      ? 'bg-yellow-600'
                      : 'bg-gray-700';

                return (
                  <div
                    key={x}
                    className={`w-[8vw] h-[8vw] sm:w-10 sm:h-10 border border-gray-600 flex items-center justify-center ${cellColor}`}
                  >
                    {isPlayer && (
                      <Square className="text-yellow-400 w-[6vw] h-[6vw] sm:w-8 sm:h-8" />
                    )}
                    {obstacle && (
                      <div className="w-[6vw] h-[6vw] sm:w-8 sm:h-8">
                        <RainbowFriend character={obstacle.character} />
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
          ))}
        </div>

        {/* Mobile Controls */}
        <div className="mt-8 grid grid-cols-3 gap-2 max-w-[200px] mx-auto sm:hidden">
          <div className="col-start-2">
            <button
              onClick={() => movePlayer('up')}
              className="w-16 h-16 bg-gray-700 rounded-lg flex items-center justify-center text-white hover:bg-gray-600 active:bg-gray-800"
            >
              <ArrowUp size={24} />
            </button>
          </div>
          <div className="col-start-1 col-end-4 flex justify-between">
            <button
              onClick={() => movePlayer('left')}
              className="w-16 h-16 bg-gray-700 rounded-lg flex items-center justify-center text-white hover:bg-gray-600 active:bg-gray-800"
            >
              <ArrowLeft size={24} />
            </button>
            <button
              onClick={() => movePlayer('down')}
              className="w-16 h-16 bg-gray-700 rounded-lg flex items-center justify-center text-white hover:bg-gray-600 active:bg-gray-800"
            >
              <ArrowDown size={24} />
            </button>
            <button
              onClick={() => movePlayer('right')}
              className="w-16 h-16 bg-gray-700 rounded-lg flex items-center justify-center text-white hover:bg-gray-600 active:bg-gray-800"
            >
              <ArrowRight size={24} />
            </button>
          </div>
        </div>

        {gameOver && (
          <div className="mt-4">
            <div className="text-red-500 text-2xl mb-2">Game Over!</div>
            <button
              onClick={resetGame}
              className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 active:bg-blue-700"
            >
              Play Again
            </button>
          </div>
        )}
        <div className="mt-4 text-gray-300 text-sm">
          {window.innerWidth < 640 ? 
            "Use the control buttons to move. Reach the green zone to score! Yellow row is safe!" :
            "Use arrow keys to move. Reach the green zone to score! Yellow row is safe!"}
        </div>
      </div>
    </div>
  );
}

export default App;
